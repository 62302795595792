<template>
  <iframe
    :src="blobUrl"
    style="width: 100%; height: 100dvh"
    ref="iframeRef"
    @load="printIframe"
  />
</template>

<script>
import jsPDF from "jspdf";
import srHelper from "../../../../helpers/sr-helpers";

export default {
  data() {
    return {
      blobUrl: null,
      ID: null,
      startCode: null,
      endCode: null,
      paramsType: "",
    };
  },
  mounted() {
    const id = this.$route.query.id;
    console.log("this",this)
    this.paramsType = sessionStorage.getItem("param_type");
    if (this.paramsType === "id") {
      this.ID = JSON.parse(sessionStorage.getItem("sr_id"));
    } else if (this.$route.query.id != undefined && sessionStorage.getItem("sr_id") == undefined){
      this.ID = this.$route.query.id;
    }
    
    console.log("printing pdf");
    console.log("this.ID", this.ID);
    this.printPDF();
  },

  beforeDestroy() {
    URL.revokeObjectURL(this.blobUrl);
    sessionStorage.removeItem("param_type");
    if (this.paramsType === "id") {
      sessionStorage.removeItem("sr_id");
    }
  },
  methods: {
    async printPDF() {
      try {
        const padding = 5;
        const height = 37.1856;
        const width = 28.6512;
        const pageWidth = width - padding * 2;
        const pageHeight = height - padding * 2;

        const pdf = new jsPDF('l');
        pdf.setFontSize(13);
        pdf.setFont("times", "bold");
        const url = await srHelper.printPDF(
          pdf,
          true,
          pageWidth,
          pageHeight,
          padding,
          this.ID
        );
        this.blobUrl = url;
      } catch (e) {
        console.log(e);
      }
    },
    printIframe() {
      if (!this.blobUrl) {
        return;
      }
      console.log("loaded");
      this.$refs.iframeRef.contentWindow.print();
    },
  },
};
</script>
